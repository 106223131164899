export const setURLs = (urls) => (dispatch) => {
    dispatch({
        type: 'SET_URLS',
        payload: urls.serviceURLs
    })
}

export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const setGlobalProcessing = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GLOBAL_PROCESSING',
        payload: value
    })
}

export const setLogin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGIN',
        payload: value
    })
}

export const setPassword = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSWORD',
        payload: value
    })
}

export const setSelectedVtcoProvider = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_VTCO_PROVIDER',
        payload: value
    })
}

export const setVtcoTokenBody = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_TOKEN_BODY',
        payload: value
    })
}

export const setVtcoSelectedKey = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_SELECTED_KEY',
        payload: value
    })
}

export const setVtcoKeysInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_KEYS_INFO',
        payload: value
    })
}

export const setProtectedParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PROTECTED_PARAMS',
        payload: value
    })
}

export const setSelectedToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_TOKEN',
        payload: value
    })
}

export const setTokenPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_PIN',
        payload: value
    })
}

export const setDetails = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DETAILS',
        payload: value
    })
}

export const setJson = (value) => (dispatch) => {
    dispatch({
        type: 'SET_JSON',
        payload: value
    })
}

export const setCihsmApi = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CIHSM_API',
        payload: value
    })
}

export const setTokenId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_ID',
        payload: value
    })
}

export const setShow2fInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_2F_INPUT',
        payload: value
    })
}

export const set2fInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_2F_INPUT',
        payload: value
    })
}

export const setNumberOfPinInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NUMBER_OF_PIN_INPUT',
        payload: value
    })
}

export const setUsedTries = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USED_TRIES',
        payload: value
    })
}

export const setOwnerName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_OWNER_NAME',
        payload: value
    })
}

export const setIsSignOperation = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_SIGN_OPERATION',
        payload: value
    })
}

export const setShowErrorPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ERROR_POPUP',
        payload: value
    })
}

export const setErrorData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ERROR_DATA',
        payload: value
    })
}