import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Body from '../components/body/Body'
import ErrorPopup from '../components/body/ErrorPopup'

class BodyContainer extends Component {

	componentDidMount() {
		
	}

	render() {
		return (
			<div>
				<Body />
				{
					this.props.base.showErrorPopup
					? <ErrorPopup />
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);