import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import platform from 'platform';
import i18next from 'i18next'
import { init } from '../actions/stringEncodersFuncs'
import { getURLs, getVersion } from '../actions/api'
import { setURLs, setVersion } from '../actions/localStates'
import BodyContainer from './BodyContainer';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import '../css/sjwsa-client-0.1.css';

import Cookies from 'universal-cookie';

import { setDetails, setJson, setProtectedParams, setSelectedToken, setCihsmApi, setTokenId, setIsSignOperation } from './../actions/localStates'
import { getOperationDetails, getProtectedParams } from './../actions/api'

const cookies = new Cookies();

class App extends Component {

	componentDidMount() {
		let _this = this, cookie;

		document.title = "IIT client"

		this.props.actions.getURLs()
		.then((response) => {
			_this.props.actions.setURLs(response)
			const encoded = decodeURI(window.location.search.split('?hash=rd/kep/')[1]);

			if (encoded !== "undefined") {
				var json = JSON.parse(encoded);

				_this.props.actions.setJson(json);

				var data = {
				    clientId : json["clientId"],
				    operationId : json["operationId"]
				}

				// eslint-disable-next-line
				_this.props.baseUrls.vtcoList.map((provider, index) =>
                    {
                    	if (json["clientId"].includes(provider.prefix)) {
                    		this.props.actions.setCihsmApi(provider.iitSignerUrl)
                    		// eslint-disable-next-line
                    		return
                    	}
                    })

				cookie = cookies.get(_this.props.baseUrls.json["clientId"], {path: '/'});
				if (cookie === undefined && json.action === "sign") {
					json["action"] = "auth"
					_this.props.actions.setJson(json);
					this.props.actions.getOperationDetails(_this.props.baseUrls.cihsmApi, data)
					.then((response) => {
						console.log(response);
						if (response.code !== undefined) {

						} else {
							_this.props.actions.setDetails(response)
							_this.props.actions.setIsSignOperation(true)
						}
					})

					return;
				}


				this.props.actions.getOperationDetails(_this.props.baseUrls.cihsmApi, data)
				.then((response) => {
					console.log(response);
					if (response.code !== undefined) {
			    		// _this.setState({isLoading: false})
			    		var msg = response.message + "\n" + response.techMessage
			    		alert(msg);
			    	} else {
			    		_this.props.actions.setDetails(response)

			    		if (json.action === "auth") {
							return;
						}

			    		_this.props.actions.getProtectedParams(_this.props.baseUrls.cihsmApi)
						.then((response) => {
							_this.props.actions.setProtectedParams(response)
							cookie = cookies.get(_this.props.baseUrls.json["clientId"], {path: '/'});
							var tokenBody = cookies.get(_this.props.baseUrls.json["clientId"] + "_token", {path: '/'});
							
							_this.props.actions.setSelectedToken(cookie)
							_this.props.actions.setTokenId(tokenBody)

							console.log(cookie)
							console.log(response)
							if (cookie === undefined && json.action === "sign") {
								// json["action"] = "auth"
								// _this.props.actions.setJson(json);
								// alert(i18next.t("initError"))
								// window.open("about:blank", "_self");
								// window.close();
							}

							document.getElementById("pinInput").focus();
							
						})
			    	}
				});
			}
		})

		this.props.actions.getVersion()
		.then((response) => {
			_this.props.actions.setVersion(response)
		})

		this.props.actions.init()	
	}

	showBrowserInfo() {
		alert(''
			+ 'Browser name  = ' + platform.name + '\n'
			+ 'Version  = ' + platform.version + '\n'
			+ 'OS = ' + platform.os + '\n'
			+ 'Description = ' + platform.description + '\n'
			+ 'Java enabled = ' + navigator.javaEnabled()
		)
	}

	goToMainSite () {
		window.open("https://cipher.com.ua/uk", '_blank').focus();
	}

	render() {
		return (
			<div>
				<div className="wrapper">
				{
					<div className="container">
						<BodyContainer />
					</div>
				}
				</div>
				<div className="footer text-right" style={{marginRight: "15px", fontSize: "12px", color: "#ebebeb"}}>
				    <label>{i18next.t("version")} {this.props.baseUrls.version} build {this.props.baseUrls.build}</label><i className="fa fa-info-circle" onClick={this.showBrowserInfo} style={{"marginLeft": "7px", "cursor": "pointer"}}></i>
				    <label onClick={this.goToMainSite} style={{display: "block", cursor: "pointer"}}>@2025 Сайфер</label>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        localesReducer: state.localesReducer,
        baseUrls: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getURLs,
	    setURLs,
	    getVersion,
	    setVersion,
	    init,
	    setDetails, setJson, getOperationDetails,
	    getProtectedParams, setProtectedParams,
	    setSelectedToken, setCihsmApi,
	    setTokenId, setIsSignOperation
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);